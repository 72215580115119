<template>
  <div id="video_section" class="mt-3">
    <div class="heading">
        <h4 class="heading-title">Videos</h4>
    </div>
    <el-carousel
      :interval="5000"
      type="card"
      height="350px"
      style="width: 100%"
      class="mt-5"
      v-if="false"
    >
      <el-carousel-item v-for="item in youtubes" :key="item.id"
      >
        <div v-html="item.url"></div>
        <!-- <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/tUF4guwJHQ8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> -->
      </el-carousel-item>
    </el-carousel>
    <carousel
      :autoplay="true"
      :autoplayTimeout="5000"
      :perPageCustom="[
        [768, 1],
        [1024, 3],
      ]"
    >
      <slide v-for="item in youtubes" :key="item.url"
      style="margin: 10px;"
      >
        <div v-html="item.url"></div>
        <!-- <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/tUF4guwJHQ8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> -->
      </slide>
    </carousel>
  </div>
</template>
<script>
import { BButton, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { Carousel, Slide } from "vue-carousel";
import store from "@/store";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    Carousel,
    Slide,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      youtubes:[]
      // youtubes: [
      //   {
      //     link: "https://www.youtube.com/watch?v=tUF4guwJHQ8",
      //     title:
      //       "香港火炭大昌單車倉簡介 (全新裝修 - 海輝地鋪分店) **完全符合消防條例**",
      //     description: "",
      //   },
      //   {
      //     link: "https://www.youtube.com/watch?v=tUF4guwJHQ8",
      //     title:
      //       "香港火炭大昌單車倉簡介 (全新裝修 - 海輝地鋪分店) **完全符合消防條例**",
      //     description: "",
      //   },
      //   {
      //     link: "https://www.youtube.com/watch?v=tUF4guwJHQ8",
      //     title:
      //       "香港火炭大昌單車倉簡介 (全新裝修 - 海輝地鋪分店) **完全符合消防條例**",
      //     description: "",
      //   },
      // ],
    };
  },
  methods:{
    getVideos(){
      store.dispatch("home/getVideos", {sortBy:'sort_order', sortDesc:false})
      .then(response => {
        this.youtubes = response.data.videos;
      })
      .catch(error => {
        console.log({error})
      })
    }  
  },
  mounted(){
    this.getVideos()
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

#video_section {
  /* background: $primary; */
  background: $background-color;
}
.know-more {
  background: $secondary;
  border-color: $secondary;
  width: 150px;
}
</style>
