<template>
  
  <div id="quick_search_bar">
    <div
      id="loc_quick_search"
      class="row d-none d-md-flex position-relative"
    >
      <div class="offset-2" style="max-width:100;">
        <el-form :inline="true" style="text-align:left;" class="mt-2">
          <img
            data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/location_white.png"
            alt="self storage location 迷你倉 storage"
            class="lazyload"
            style="height:35px;"
          />
          <span class="ml-3 font-weight-bold">尋找分店</span>
          <el-select
            class="ml-3" 
            v-model="store_type_id"
            placeholder="迷你倉類型"
            clearable
          >
          <el-option v-for="(item, index) in warehouseOptions" :key="`store_${index}`"
          :label="item.name"
          :value="item.id"
          >
          </el-option>

          </el-select>
          <b-button
          class="ml-3 search-button"
          
          :variant="variant"
          v-if="store_type_id"
          :to="{name: 'warehouse', params: { id: store_type_id } }"
          >
            Search
          </b-button>


        </el-form>
   
      </div>

      <div id="resrv_form" class="d-none d-md-block no-background" v-if="false">
        <div class="p-0 w-100">
          <div
            class="w-50 text-center top-bar pl-2 text-white"
            onclick="toggleResrvForm('resrv_form', 'resrv_form_body')"
          >
            <img
              data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/book.png"
              alt="預訂 自存倉 迷你倉 storage"
              class="lazyload"
            />
            預約睇倉
            <img
              class="expand-form-icon ml-1 lazyload"
              data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/down_arrow_white.png"
              alt="迷你倉預約 迷你倉 storage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {BButton} from 'bootstrap-vue'
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
export default {
  components:{
    BButton
  },
  computed:{
    variant(){
      return (this.store_type_id == null || this.store_type_id == '')? '': 'success';
    }
  },
  data() {
    return {
        store_type_id: null,
        location_id: null,
        warehouseOptions:null
    };
  },
  methods:{
    fetchOptions(){
      store
        .dispatch("fetchOptions", { warehouse_category: true })
        .then((response) => {
          this.warehouseOptions = response.data.warehouse_categories;
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  },
  mounted(){
    this.fetchOptions();
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

#loc_quick_search {
  background-color: $primary !important;
}

.search-button{
  background: $secondary;
}

</style>
