<template>
  <header class="text-white sticky-top">
    <modal-login />
    <modal-register />
    <modal-forgot-password />
    <modal-edit-password />
    <div id="desktop_menu" class="d-none d-lg-block">
      <div id="topQuickLinkBar" class="row justify-content-around">
        <span id="quickLinkPointer" v-if="false"></span>
        <!--
        <div class="pt-2" v-for="item in topMenus" :key="item.name">
          <a>{{ item.name }}</a>
        </div>
        -->
      </div>
      <div id="top_logo_box" class="d-flex align-items-center justify-content-between mx-auto py-2">
        <div id="top_logo_img" class="text-center h-100">
          <a href="/">
            <img
              src="@/assets/logo.webp"
              alt="Store Friendly Logo 迷你倉 storage"
              class="lazyload"
            />
            <img
              src="@/assets/logo-word.webp"
              alt="Store Friendly Logo 迷你倉 storage"
              class="lazyload"
            />
          </a>
        </div>
        <div class="h-100">
          <div
            v-if="$store.state.settings && $store.state.settings.length > 0"
            id="menu_header"
            class="h-50 d-flex pt-3 justify-content-center align-items-center"
          >
            <a
              :href="$store.state.settings[findInvoiceIndex('facebook')].value"
              class="d-inline-block ml-1"
              target="_blank"
              rel="noopener noreferrer"
              ><img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/facebook.svg"
                alt=""
                class="lazyload"
            /></a>
            <a
              :href="`https://wa.me/${$store.state.settings[
                findInvoiceIndex('tel')
              ].value
                .split(' ')
                .join('')}`"
              onclick="connectWhatsapp('')"
              class="d-inline-block ml-2"
              ><img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/whatsapp.svg"
                alt=""
                class="lazyload"
            /></a>
            <div class="d-inline-block mx-3 separator"></div>
            <img
              class="mt-1 lazyload"
              data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/phone.svg"
              alt=""
            />
            <span class="main_blue_text ml-2 font-weight-bold text-primary"
              >{{ $store.state.settings[findInvoiceIndex("tel")].value }}
            </span>
            <div class="d-inline-block mx-3 separator"></div>
            <b-button variant="success" target="_blank" href="https://www.pgyer.com/ybJGMU">
              下載 APP
            </b-button>
          </div>
          <div id="menu_body" class="h-50">
            <nav id="desktop_navbar" class="navbar navbar-expand row">
              <div class="collapse navbar-collapse">
                <ul class="navbar-nav mr-auto">
                  <!-- menu banner language by Walter on 25 May 2020 -->
                  <!-- add firstpage by Walter on 29 Mar 2022 -->
                  <li
                    :class="{
                      'nva-item': true,
                      'ml-3': true,
                      dropdown: item.children,
                    }"
                    v-for="item in middleMenus"
                    :key="`middle-${item.name}`"
                  >
                    <router-link
                      :is="item.children ? 'span' : 'router-link'"
                      :class="{
                        'nav-link': true,
                        'dropdown-toggle': item.children,
                      }"
                      :to="item.to"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      >{{ item.name }}</router-link
                    >
                    <div class="dropdown-menu" v-if="item.children">
                      <div v-if="item.children.length > 0">
                        <router-link
                          v-for="(subitem, index) in item.children"
                          :key="`subitem-${index}-${subitem.name}`"
                          class="dropdown-item"
                          :to="{
                            name: 'warehouse-detail',
                            params: {
                              warehouse_id: subitem.id,
                            },
                          }"
                         
                          >{{ subitem.name }}</router-link
                        >
                      </div>
                      <div v-else class="blank_item">
                        暫時沒有{{ item.name }}
                      </div>
                    </div>
                  </li>
                  <li :class="{ 'nva-item': true, 'ml-3': true }">
                    <div
                      class="d-flex align-items-center"
                      v-if="!$store.state.userData"
                    >
                      <a
                        class="nav-link pr-0"
                        href="javascript:void(0)"
                        @click="$bvModal.show('modal-login')"
                        >登入</a
                      >
                      <span class="login-seq p-2">|</span>
                      <a
                        class="nav-link"
                        href="javascript:void(0)"
                        @click="$bvModal.show('modal-register')"
                        >註冊</a
                      >
                    </div>

                    <b-navbar-nav v-else class="nav">
                      <b-nav-item-dropdown
                        rightx
                        toggle-class="dropdown-user-link"
                        class="dropdown-user"
                      >
                        <template #button-content>
                          <div class="text-center nav-user-info">
                            <div class="nav-user-info_name">
                              {{ $store.state.userData.name }}
                            </div>
                          </div>
                        </template>

                        <b-dropdown-item
                          link-class="d-flex align-items-center"
                          :to="{ name: 'personal-center' }"
                        >
                          <users-icon size="16" class="mr-1"></users-icon>

                          <span>個人中心</span>
                        </b-dropdown-item>

                        <b-dropdown-divider />
                        
                        <b-dropdown-item
                          link-class="d-flex align-items-center"
                          :to="{ name: 'invoice' }"
                        >
                          <file-text-icon size="16" class="mr-1"></file-text-icon>

                          <span>發票記錄</span>
                        </b-dropdown-item>

                        <b-dropdown-divider />
                        
                        <b-dropdown-item
                          link-class="d-flex align-items-center"
                          :to="{ name: 'payment-records',params:{customer_id: $store.state.userData.id } }"
                        >
                          <file-text-icon
                            size="16"
                            class="mr-1"
                          ></file-text-icon>

                          <span>租貸記錄</span>
                        </b-dropdown-item>

                        <b-dropdown-divider />

                        <b-dropdown-item
                          link-class="d-flex align-items-center"
                          @click="handleEditPassword"
                        >
                          <edit-icon size="16" class="mr-1"></edit-icon>

                          <span>改變密碼</span>
                        </b-dropdown-item>

                        <b-dropdown-divider />

                        <b-dropdown-item
                          link-class="d-flex align-items-center"
                          @click="handleLogout"
                        >
                          <log-out-icon size="16" class="mr-1"></log-out-icon>

                          <span>登出</span>
                        </b-dropdown-item>
                      </b-nav-item-dropdown>
                    </b-navbar-nav>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="row bottom-line" style="height: 6px; margin-top: -6px">
        <div class="offset-6 col-6"></div>
      </div>
    </div>
    <!-- Mobile Menu -->
    <nav
      id="mobile_menu"
      class="navbar navbar-expand-lg row justify-content-start d-lg-none"
      style="margin-left: 0; margin-right: 0"
    >
      <div class="col-7 pr-0 pb-2">
        <a href="/">
          <img
            src="@/assets/logo-word.webp"
            class="mw-100 lazyload"
            alt="儲存易 LOGO 迷你倉 storage"
          />
        </a>
      </div>
      <div class="offset-1 col-4 px-0 pb-2 row align-items-center">
        <div class="col p-0">
          <a
            v-if="$store.state.settings && $store.state.settings.length > 0"
            :href="`tel://${
              $store.state.settings[findInvoiceIndex('tel')].value
            }`"
          >
            <img
              data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/phone.svg"
              style="height: 22px"
              alt="Storage Phone 迷你倉 storage"
              class="lazyload"
            />
          </a>
        </div>
        <div class="col p-0">
          <!-- logo language by Walter on 25 May 2020 -->
          <a href="#">
            <img
              data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/location.svg"
              style="height: 22px"
              alt="Self Storage Location 迷你倉 storage"
              class="lazyload"
            />
          </a>
        </div>
        <div class="col p-0">
          <button
            id="button_tmp_19June"
            class="navbar-toggler p-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li
            :class="{
              'nav-item': true,
              dropdown: $store.state.userData,
            }"
            @click="closeMenu($store.state.userData)"
          >
            <div
              class="d-flex justify-content-between my-2 align-items-center"
              
            >
              <div v-if="!$store.state.userData" class="d-flex align-items-center pl-4">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  @click="$bvModal.show('modal-login')"
                  >登入</a
                >
                <span class="login-seq p-2">|</span>
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  @click="$bvModal.show('modal-register')"
                  >註冊</a
                >
              </div>
              <div v-else class="d-flex my-2 justify-content-between align-items-center" >
                <b-navbar-nav  class="nav">
                  <b-nav-item-dropdown
                    rightx
                    toggle-class="dropdown-user-link"
                    class="dropdown-user"
                  >
                    <template #button-content>
                      <div class="text-center nav-user-info">
                        <div class="nav-user-info_name">
                          {{ $store.state.userData.name }}
                        </div>
                      </div>
                    </template>

                    <b-dropdown-item
                      link-class="d-flex align-items-center"
                      :to="{ name: 'personal-center' }"
                    >
                      <users-icon size="16" class="mr-1"></users-icon>

                      <span>個人中心</span>
                    </b-dropdown-item>

                    <b-dropdown-divider />

                    <b-dropdown-item
                      link-class="d-flex align-items-center"
                      :to="{ name: 'payment-records' }"
                    >
                      <file-text-icon size="16" class="mr-1"></file-text-icon>

                      <span>租貸記錄</span>
                    </b-dropdown-item>

                    <b-dropdown-divider />
                    <b-dropdown-item
                      link-class="d-flex align-items-center"
                      @click="handleEditPassword"
                    >
                      <edit-icon size="16" class="mr-1"></edit-icon>

                      <span>改變密碼</span>
                    </b-dropdown-item>

                    <b-dropdown-divider />

                    <b-dropdown-item
                      link-class="d-flex align-items-center"
                      @click="handleLogout"
                    >
                      <log-out-icon size="16" class="mr-1"></log-out-icon>

                      <span>登出</span>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </b-navbar-nav>
              </div>

                <b-button variant="success" class="mr-4" target="_blank" href="https://www.pgyer.com/ybJGMU">
                  下載 APP
                </b-button>
            </div>
          </li>
          <!-- menu banner language by Walter on 26 May 2020 -->
          <li
            :class="{
              'nav-item': true,
              dropdown: item.children && item.children.length > 0,
            }"
            v-for="(item, index) in middleMenus"
            :key="`mobile-menu-${index}`"
            @click="closeMenu(item.children && item.children.length > 0)"
          >
            <router-link
              :class="{
                'nav-link': true,
                'ml-4': true,
                'dropdown-toggle': item.children && item.children.length > 0,
              }"
              :is="item.children ? 'span' : 'router-link'"
              :to="item.to"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ item.name }}
            </router-link>
            <div
              class="dropdown-menu m-0 py-2"
              style="border-radius: 0px"
              v-if="item.children && item.children.length > 0"
              @click="closeMenu(false)"
            >
              <router-link
                class="dropdown-item py-2"
                :to="{
                  name: 'warehouse-detail',
                  params: { warehouse_id: subitem.id },
                }"
                v-for="(subitem, index) in item.children"
                :key="`mobile-submenu-${index}`"
                >{{ subitem.name }}</router-link
              >
            </div>
          </li>

          <div class="mobile-social py-3 px-3 d-flex justify-content-start">
            <a
              v-if="$store.state.settings && $store.state.settings.length > 0"
              :href="`tel://${
                $store.state.settings[findInvoiceIndex('tel')].value
              }`"
              target="_blank"
              class="d-inline-block ml-1"
              rel="noopener noreferrer"
              ><img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/facebook.svg"
                style="height: 30px"
                alt=""
                class="lazyload"
            /></a>
            <a
              v-if="$store.state.settings && $store.state.settings.length > 0"
              :href="`https://wa.me/${$store.state.settings[
                findInvoiceIndex('tel')
              ].value
                .split(' ')
                .join('')}`"
              onclick="connectWhatsapp('')"
              class="d-inline-block ml-2"
              ><img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/whatsapp.svg"
                style="height: 30px"
                alt=""
                class="lazyload"
            /></a>
          </div>
        </ul>
      </div>
    </nav>
    <home-search />
  </header>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";

import axiosIns from "@/libs/axios";
import HomeSearch from "@/views/home/HomeSearch.vue";
import ModalForgotPassword from "@/views/pages/authentication/ForgotPassword.vue";
import ModalRegister from "@/views/pages/authentication/Register.vue";
import ModalLogin from "@/views/pages/authentication/ModalLogin.vue";
import ModalEditPassword from "@/views/pages/authentication/EditPassword.vue";
// import ToastificationContent from "@/libs/toastification/ToastificationContent";
import {
  UserCheckIcon,
  UsersIcon,
  EditIcon,
  LogOutIcon,
  FileTextIcon,
} from "vue-feather-icons";
import { mapGetters } from "vuex";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BButton,
    BDropdownItem,
    BDropdownDivider,
    HomeSearch,
    ModalLogin,
    ModalRegister,
    ModalForgotPassword,
    ModalEditPassword,
    UserCheckIcon,
    UsersIcon,
    EditIcon,
    LogOutIcon,
    FileTextIcon,
  },
  methods: {
    connectWhatsapp() {},
    closeMenu(status) {
      if (!status) {
        document.getElementById("button_tmp_19June").click();
      }
    },
    handleEditPassword() {
      // this.$root.$emit("bv::show::modal", "modal-edit-password", "#btnShow");
      this.$bvModal.show("modal-edit-password");
    },
    handleLogout() {
      //Request server to void the token
      axiosIns.get("/logout");
      localStorage.clear();
      this.$store.commit("updateUserData");

      this.$swal({
        text: "你已經成功登出！",
        icon: "success",
        timer: 2000,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      });
      // this.$toast({
      //   component: ToastificationContent,
      //   position: "top-right",
      //   props: {
      //     title: `Success`,
      //     icon: "CoffeeIcon",
      //     variant: "success",
      //     text: `You have successfully logged out!`,
      //   },
      // });
      this.$router.go();
    },
    fetchWarehouse() {
      this.$store
        .dispatch("fetchWarehouses")
        .then((response) => {
          const warehouse_list = response.data.warehouses;
          var miniChild =
            this.middleMenus[
              this.middleMenus.findIndex((item) => item.name === "迷你倉")
            ].children;
          var bicycleChild =
            this.middleMenus[
              this.middleMenus.findIndex((item) => item.name === "單車倉")
            ].children;
          var workshop =
            this.middleMenus[
              this.middleMenus.findIndex((item) => item.name === "工作間")
            ].children;

          miniChild = [
            ...warehouse_list.filter((ele) => ele.warehouse_category_id === 1),
          ];
          bicycleChild = [
            ...warehouse_list.filter((ele) => ele.warehouse_category_id === 2),
          ];
          workshop = [
            ...warehouse_list.filter((ele) => ele.warehouse_category_id === 3),
          ];

          // miniChild = miniChild.length > 0 ? miniChild : [{name:'暫時沒有迷你倉'}]
          // bicycleChild = bicycleChild.length > 0 ? bicycleChild : [{name:'暫時沒有單車倉'}]
          // workshop = workshop.length > 0 ? workshop : [{name:'暫時沒有工作間'}]

          this.middleMenus[
            this.middleMenus.findIndex((item) => item.name === "迷你倉")
          ].children = miniChild;
          this.middleMenus[
            this.middleMenus.findIndex((item) => item.name === "單車倉")
          ].children = bicycleChild;
          this.middleMenus[
            this.middleMenus.findIndex((item) => item.name === "工作間")
          ].children = workshop;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    fetchCustomer() {
      // init run once
      this.$store
        .dispatch("fetchCustomer", localStorage.getItem("accessToken"))
        .then((res) => {
          this.isInit = false;
          this.$store.commit("updateUserData", res.data.customer);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters({
      findInvoiceIndex: "findInvoiceIndex",
    }),
  },
  mounted() {
    this.fetchWarehouse();
    if (localStorage.getItem("accessToken")) {
      this.fetchCustomer();
    }
  },
  data() {
    return {
      isInit: true,
      middleMenus: [
        { name: "主頁", to: "/" },
        { name: "關於我們", to: "/about" },
        {
          id: 1,
          name: "迷你倉",
          to: "/",
          children: [],
        },
        {
          id: 2,
          name: "單車倉",
          to: "/",
          children: [],
        },
        {
          id: 3,
          name: "工作間",
          to: "/",
          children: [],
        },
        { name: "聯絡我們", to: "/contact" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

#top_logo_box {
  background: $backgrond-color;
  max-width: 1320px;
}

#desktop_navbar {
  background: $backgrond-color;
}
#desktop_menu {
  background: #fff;
}
#desktop_menu .bottom-line div {
  border-bottom: 6px $secondary solid;
  border-left: 6px $backgrond-color solid;
}

#topQuickLinkBar {
  background: $primary;
  border-top: 6px $secondary solid;
}

#quickLinkPointer {
  background-color: $secondary;
}

#desktop_navbar a {
  color: $primary;
}
.text-primary {
  color: $primary !important;
}

#mobile_menu {
  background: $backgrond-color;
}

#desktop_navbar .nav-link {
  color: $primary;
  font-weight: 700;
}
#desktop_navbar a {
  color: white;
}

#desktop_navbar .dropdown-menu {
  background: $text-color;
}

.login-seq {
  color: $primary;
}
#desktop_navbar .dropdown-menu a.router-link-active {
  background: $primary;
}
#mobile_menu .nav-item {
  background: $primary;
  text-align: left;
}

.mobile-social {
  background: $primary;
}

.blank_item {
  color: rgba(255, 255, 255, 0.702);
  width: 100%;
  padding: 0.25rem 1.5rem;
  white-space: nowrap;
}
</style>
<style lang="scss">
.dropdown-user .dropdown-toggle {
  display: flex;
  align-items: center;
  padding-left: 0.5rem !important;
}

.nav-user-info_name {
  margin-right: 0.5rem;
  svg {
    margin-right: 0.5rem !important;
  }
}

@media screen and (max-width: 768px) {
  .dropdown-menu {
    .dropdown-item:hover,
    .dropdown-item:focus,
    .dropdown-item:active {
      background-color: transparent !important;
    }
  }
  .dropdown-user {
    .dropdown-toggle {
      padding-left: 1.5rem !important;
      border-width: 0px;
      border-bottom-width: 1px;
      border-color: #bc8d34;
    }
    ul.dropdown-menu {
      width: 100%;
    }
  }

  .login-seq {
    color: #bc8d34;
  }
}
</style>